<template>
  <div class="grid-page-wrapper">
    <h1 class="display-4">Registries dashboard > Closed registries</h1>
    <h1 class="display-6"> {{ start_date_formatted }} - {{ end_date_formatted }}</h1>
    <hr />
    <div class="row mb-2">
        <div class="col-md-3">
            <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                <div class="col p-4 d-flex flex-column position-static bg-card">
                    <!-- <strong class="d-inline-block mb-2 text-success">Design</strong> -->
                    <h5 class="mb-2 text-primary">Total time registred (HH:mm:ss)</h5>
                    <!-- <div class="mb-1 text-muted">Nov 12</div> -->
                    <strong class="card-text mb-auto">{{ metrics.totalTime }}</strong>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
      <div class="col-12">
        <button
          class="btn btn-secondary dropdown-toggle"
          id="btn-dropdown-export-table"
          data-bs-toggle="dropdown"
        >
          Export
        </button>
        <ul class="dropdown-menu" aria-labelledby="btn-dropdown-export-table">
          <li>
            <button class="dropdown-item" @click="onBtnExport()">
              <i class="fa-solid fa-file-csv"></i> CSV
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="grid-wrapper">
      <ag-grid-vue
        class="backoffice-grid ag-theme-alpine"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :paginationAutoPageSize="true"
        :pagination="true"
        :frameworkComponents="frameworkComponents"
        @grid-ready="onGridReady"
        @model-updated="onGridModelUpdated"
        :rowClassRules="rowClassRules"
        :rowData="rowData"
      >
      </ag-grid-vue>
    </div>
    <div class="row mt-2">
        <div class="col">
            <button @click="routeBack" class="btn btn-outline-secondary float-end">
                <i class="bi bi-arrow-return-left"></i> Go back
            </button>
        </div>
    </div>
  </div>
</template>

<script>
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import { ref, reactive } from 'vue'
import { AgGridVue } from "ag-grid-vue3";
import { useRouter } from 'vue-router'

import GridColumnSelectFilter from "../../shared/components/GridColumnSelectFilter"
import { timeRenderer, dateRenderer } from "../../shared/helpers/GridRenderers";
import { seconds2TimeString } from '../../shared/helpers/dateTimeHelpers'

import { getClosedRegistries } from "../helpers/endpoints";
import { useRegistriesGrid } from "../composables/useRegistriesGrid";

export default {
  components: {
    AgGridVue,
  },
  props: {
    start_date: {
      required: true,
      type: Date,
    },
    end_date: {
      required: true,
      type: Date,
    },
  },
  setup(props) {
    const router = useRouter()
    const sumRegistryTime = ref('-')

    const {
      statusColumnDef,
      defaultColDef,
      rowData,
      onGridReady,
      exportAsCSV,
      timeSpendRenderer,
      rowClassRules
    } = useRegistriesGrid(() => {
        getClosedRegistries(props.start_date, props.end_date).then((response) => {
            rowData.value = response;
            console.log(rowData.value);
            const totalSeconds = rowData.value.reduce(
                (acc, currentValue) => acc + currentValue.time_spend, 
                0
            );
            const hours = Math.floor(totalSeconds / 3600);
            let remSeconds = totalSeconds % 3600;
            const minutes = Math.floor(remSeconds / 60);
            const seconds = remSeconds % 60;
            
            let timeString = '';
            if (hours > 0) {
                timeString += `${hours} hours `;
            }
            if (minutes > 0) {
                timeString += `${minutes} minutes `;
            }
            if (seconds > 0) {
                timeString += `${seconds} seconds `;
            }
            
            sumRegistryTime.value = (timeString == '') ? '0 seconds' : timeString;
        });
    });

    const metrics = reactive({
        'totalTime': 0
    })

    return {
      columnDefs: [
        { 
          headerName: "User", field: "user", filter: "agTextColumnFilter" 
        },
        {
          headerName: "Order",
          field: "production_order",
          filter: "agTextColumnFilter",
        },
        {
          headerName: "Date",
          field: "start_time",
          cellRenderer: dateRenderer,
        },
        {
          headerName: "Start time",
          field: "start_time",
          cellRenderer: timeRenderer,
        },
        {
          headerName: "End time",
          field: "end_time",
          cellRenderer: timeRenderer,
        },
        {
          headerName: "Registry time (HH:mm:ss)",
          field: "time_spend",
          cellRenderer: timeSpendRenderer,
        },
        statusColumnDef,
      ],
      frameworkComponents: {
          selectFilter: GridColumnSelectFilter
      },
      defaultColDef,
      rowClassRules,

      rowData,
      onGridReady,
      onBtnExport: () => exportAsCSV(),

      sumRegistryTime,
      start_date_formatted: props.start_date.toISOString().split('T')[0],
      end_date_formatted: props.end_date.toISOString().split('T')[0],


      metrics,

      onGridModelUpdated: (params) => {
          console.log(params);
          const {api, keepRenderedRows} = params
          if (keepRenderedRows) {
              console.log("Model Updated -> same rows");
          } else {
              console.log("Model Update -> new rows");
          }

          const rowModel = api.getModel()
          metrics['totalTime'] = 0
          rowModel.forEachNodeAfterFilter((rowNode) => {
              metrics['totalTime'] += rowNode.data.time_spend
          });

          metrics['totalTime'] = seconds2TimeString(metrics['totalTime'])
      },

      routeBack: () => router.back()
    };
  },
};
</script>
